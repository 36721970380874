import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/vkuramshin/p/www/tkcrm/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "мобильное-приложение"
    }}>{`Мобильное приложение`}</h1>
    <p>{`Для того, чтобы прнимать данные с мобильного приложения, нужно:`}</p>
    <ol>
      <li parentName="ol">{`Создайте водителя / курьера`}</li>
      <li parentName="ol">{`Укажите транспортное средство, на котором он передвигается`}</li>
      <li parentName="ol">{`Скачайте на телефон водителя приложение `}<strong parentName="li">{`TKCRM Driver`}</strong>{` из Play Market`}</li>
      <li parentName="ol">{`На вкладке трекер активируйте прием данных`}</li>
    </ol>
    <p><img alt="TKCRM Driver tracker activation" src={require("../public/images/driver-tracker.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      